import React, {useCallback, useEffect, useState} from 'react';

import '../css/style.css';
import '../css/react-tags.css';
import Breadcrumb from '../components/breadcrumb';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Textarea } from '@material-tailwind/react';
import MySQLUrl from "../DBConfig";
import {HomeIcon, TrashIcon} from "@heroicons/react/24/solid";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {PlusIcon} from "@heroicons/react/20/solid";
import { ReactTags } from 'react-tag-autocomplete'
import { suggestions } from '../components/countries'


const NewApplicant = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const formattedTime = currentTime.toLocaleTimeString();
    const [file, setFile] = useState(null);
    const [coverPhotoFile, setCoverPhotoFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [selected, setSelected] = useState([]);
    const [photoPreview, setPhotoPreview] = useState(null);
    const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const[accessToken, setAccessToken]=useState('');

    const [options, setOptions] = useState({
        activateFirstOption: false,
        allowBackspace: false,
        collapseOnSelect: false,
        isDisabled: false,
        isInvalid: false,
    })


    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                const accessToken = localStorage.getItem('accessToken');

                const parsedToken = accessToken;

                if(!parsedToken){
                    localStorage.removeItem('smsToken');
                    localStorage.removeItem('smsUserData');
                    localStorage.removeItem('user');
                    navigate('/login');
                }

               setAccessToken(parsedToken);

            }else{
                localStorage.removeItem('accessToken');
                localStorage.removeItem('smsToken');
                localStorage.removeItem('smsUserData');
                localStorage.removeItem('user');
                navigate('/login');
            }
        };

        // Initial data fetch
        fetchData();
    }, []);

    const [formData, setFormData] = useState({
        teegee_ref:'',
        fullName: '',
        postApplied: '',
        educationalQualifications: [''],
        salary: '',
        currency:'',
        contractPeriod: '',
        gender:'',
        nationality: '',
        religion: '',
        DOB: '',
        birthPlace: '',
        livingTown: '',
        maritalStatus: '',
        noOfChildren: '',
        Weight: '',
        Height: '',
        Complexion: '',
        Age: '',
        english: '',
        arabic: '',
        Driving: '',
        Sewing: '',
        cooking: '',
        washing: '',
        cleaning: '',
        baby_sitting: '',
        passportNo: '',
        issuedPlace: '',
        IssuedDate: '',
        ExpiryDate: '',
        previousEmployments: [{ country: '', duration: '' }],
        Photo: '',
        coverPhoto: '',
        countries:[''],
        telephone:'',
    });


    const handleFileChange = (event, setPreview, setFile, fileKey) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPreview(reader.result);
            if (fileKey === 'Photo') {
                setFile(file); // Update file state variable for photo
            } else if (fileKey === 'coverPhoto') {
                setCoverPhotoFile(file); // Update coverPhotoFile state variable
            }
            setFormData(prevData => ({
                ...prevData,
                [fileKey]: file, // Update the corresponding property in formData
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    // Function to handle adding a country tag
    const handleAdd = (tag) => {
        // console.log('Adding tag:', tag.label);
        setSelected([...selected, tag]);
        setFormData({ ...formData, countries: [...formData.countries, tag.label] });
    };

    // Function to handle deleting a country tag
    const handleDelete = (i) => {
        const newSelected = selected.slice();
        newSelected.splice(i, 1);
        setSelected(newSelected);

        const newCountries = formData.countries.slice();
        newCountries.splice(i, 1);
        setFormData({ ...formData, countries: newCountries }); // Update formData with the deleted country

    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            Age: name === 'DOB' ? calculateAge(value) : prevData.Age
        }));
    };


    const handlePreviousEmploymentChange = (index, field, value) => {
        const updatedEmployments = [...formData.previousEmployments];
        updatedEmployments[index][field] = value;
        setFormData(prevData => ({
            ...prevData,
            previousEmployments: updatedEmployments,
        }));
    };

    const handleAddPreviousEmployment = () => {
        setFormData(prevData => ({
            ...prevData,
            previousEmployments: [...prevData.previousEmployments, { country: '', duration: '' }],
        }));
    };

    const handleRemovePreviousEmployment = (index) => {
        // Check if there's only one previous employment entry left
        if (formData.previousEmployments.length === 1) {
            toast.error("Fill at least one employment or use '-'")
            return;
        }

        // Remove the specified entry
        setFormData(prevData => ({
            ...prevData,
            previousEmployments: prevData.previousEmployments.filter((_, i) => i !== index),
        }));
    };


    const handleQualificationChange = (index, value) => {
        const updatedQualifications = [...formData.educationalQualifications];
        updatedQualifications[index] = value;
        setFormData(prevData => ({
            ...prevData,
            educationalQualifications: updatedQualifications,
        }));
    };

    const handleAddQualification = () => {
        setFormData(prevData => ({
            ...prevData,
            educationalQualifications: [...prevData.educationalQualifications, ''],
        }));
    };

    const handleRemoveQualification = (index) => {
        // Check if there's only one qualification entry left
        if (formData.educationalQualifications.length === 1) {
            toast.error("Fill at least one qualification or use '-'")
            return;
        }
        setFormData(prevData => ({
            ...prevData,
            educationalQualifications: prevData.educationalQualifications.filter((_, i) => i !== index),
        }));
    };

    function calculateAge(dob) {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }



    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setLoading(true); // Set loading to true when form is submitted
        setDisabled(true); // Disable submit button

        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            console.log(config);

            const formDataToSend = new FormData();

            const monthlySalary = `${formData.salary} ${formData.currency}`;

            formDataToSend.append('teegee_ref', formData.teegee_ref);
            formDataToSend.append('postApplied', formData.postApplied);
            formDataToSend.append('monthly_Salary', monthlySalary);
            formDataToSend.append('contractPeriod', formData.contractPeriod);
            formDataToSend.append('gender', formData.gender);
            formDataToSend.append('fullName', formData.fullName);
            formDataToSend.append('nationality', formData.nationality);
            formDataToSend.append('religion', formData.religion);
            formDataToSend.append('DOB', formData.DOB);
            formDataToSend.append('birthPlace', formData.birthPlace);
            formDataToSend.append('livingTown', formData.livingTown);
            formDataToSend.append('maritalStatus', formData.maritalStatus);
            formDataToSend.append('noOfChildren', formData.noOfChildren);
            formDataToSend.append('Weight', formData.Weight);
            formDataToSend.append('Height', formData.Height);
            formDataToSend.append('Complexion', formData.Complexion);
            formDataToSend.append('Age', formData.Age);
            formDataToSend.append('english', formData.english);
            formDataToSend.append('arabic', formData.arabic);
            formDataToSend.append('Driving', formData.Driving);
            formDataToSend.append('Sewing', formData.Sewing);
            formDataToSend.append('cooking', formData.cooking);
            formDataToSend.append('washing', formData.washing);
            formDataToSend.append('cleaning', formData.cleaning);
            formDataToSend.append('baby_sitting', formData.baby_sitting);
            formDataToSend.append('passportNo', formData.passportNo);
            formDataToSend.append('issuedPlace', formData.issuedPlace );
            formDataToSend.append('IssuedDate', formData.IssuedDate );
            formDataToSend.append('ExpiryDate', formData.ExpiryDate );
            formDataToSend.append('telephone', formData.telephone );

            formData.educationalQualifications.forEach((qualification, index) => {
                formDataToSend.append(`educationalQualifications.qualification[]`, qualification);
            });


            formData.previousEmployments.forEach((employment, index) => {
                formDataToSend.append(`previousEmployments.country`, employment.country);
                formDataToSend.append(`previousEmployments.duration`, employment.duration);
            });

            // Append selected countries from ReactTags component
            selected.forEach((tag) => {
                formDataToSend.append(`countries`, tag.label);
            });

            // Append uploaded files
            formDataToSend.append('Photo', file);
            formDataToSend.append('coverPhoto', coverPhotoFile);


            console.log(formDataToSend);

            // Send form data
            const response = await axios.post(`${MySQLUrl}/applicant/new-applicant`, formDataToSend, config);
            console.log('Response from the server:', response.data);

            setLoading(false); // Set loading to false when response is received
            setDisabled(false);

            // Clear form data and preview images
            setFormData({
                teegee_ref:'',
                fullName: '',
                postApplied: '',
                educationalQualifications: [''],
                monthly_Salary: '',
                contractPeriod: '',
                gender:'',
                nationality: '',
                religion: '',
                DOB: '',
                salary: '',
                currency: '',
                birthPlace: '',
                livingTown: '',
                maritalStatus: '',
                noOfChildren: '',
                Weight: '',
                Height: '',
                Complexion: '',
                english: '',
                arabic: '',
                Driving: '',
                Sewing: '',
                cooking: '',
                washing: '',
                cleaning: '',
                baby_sitting: '',
                passportNo: '',
                issuedPlace: '',
                IssuedDate: '',
                ExpiryDate: '',
                previousEmployments: [{ country: '', duration: '' }],
                countries:[],
                telephone:'',
            });
            setPhotoPreview(null);
            setCoverPhotoPreview(null);
            setFile(null);
            toast.success("Successfully added applicant")

            // Optionally, you can redirect the user to a new page or show a success message
        } catch (error) {
            setLoading(false); // Set loading to false when error occurs
            setDisabled(false);
            toast.error('Error adding applicant');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }
    };




    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Applicant', url: '/applicant' },
        { label: 'New Applicant', url: '/new-applicants' },
    ];



    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={5000} // Close after 5 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


            <div>
                <div className="mx-4 text-neutral-800 overflow-y-auto">
                    <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">

                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 mt-0">
                            <div className="flex items-center">
                                <h2 className="mr-5 text-lg font-medium truncate ">New Applicants</h2>
                            </div>
                        </div>



                        <div className="col-span-12 mt-1">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                                <div className="bg-white p-4 shadow-lg rounded-lg h-[65vh] overflow-y-auto">

                                    {/* Form */}
                                    <form onSubmit={handleFormSubmit}>

                                        {/* 1*/}

                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="fullName" className="block text-sm font-medium text-gray-600">
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fullName"
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    placeholder="Eg: John Doe"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />
                                                {formData.fullName.length === 0 && (
                                                    <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                )}
                                                {formData.fullName.length >= 10 && !formData.fullName.match(/[^a-zA-Z]+$/) && (
                                                    <p className="text-green-500 text-xs italic">Looks Good</p>
                                                )}
                                                {formData.fullName.length > 0 && formData.fullName.length < 10 && (
                                                    <p className="text-red-500 text-xs italic">Full name must be at least 10 characters long.</p>
                                                )}
                                                {formData.fullName.length >= 10 && formData.fullName.match(/[^a-zA-Z]+$/) && (
                                                    <p className="text-red-500 text-xs italic">Please use only alphabets.</p>
                                                )}


                                            </div>

                                            <div className="mb-1 grid grid-cols-2 gap-4">

                                            <div>
                                                <label htmlFor="postApplied" className="block text-sm font-medium text-gray-600">
                                                    Post Applied
                                                </label>
                                                <input
                                                    type="text"
                                                    id="postApplied"
                                                    name="postApplied"
                                                    value={formData.postApplied}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    placeholder="Eg: House Cleaner"
                                                    required
                                                />
                                                {formData.postApplied.length === 0 && (
                                                    <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                )}
                                                {formData.postApplied.length >= 5 && !formData.postApplied.match(/[^a-zA-Z]+$/) && (
                                                    <p className="text-green-500 text-xs italic">Looks Good</p>
                                                )}
                                                {formData.postApplied.length > 0 && formData.postApplied.length < 5 && (
                                                    <p className="text-red-500 text-xs italic">Must be at least 5 characters long.</p>
                                                )}
                                                {formData.postApplied.length >= 5 && formData.postApplied.match(/[^a-zA-Z]+$/) && (
                                                    <p className="text-red-500 text-xs italic">Please use only alphabets.</p>
                                                )}

                                            </div>


                                                <div>
                                                    <label htmlFor="teegee_ref" className="block text-sm font-medium text-gray-600">
                                                        Reference No
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="teegee_ref"
                                                        name="teegee_ref"
                                                        value={formData.teegee_ref}
                                                        placeholder="Eg: #SA123"
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                        required
                                                    />
                                                    {formData.teegee_ref.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.teegee_ref.length >= 4 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                        )}



                                                </div>

                                            </div>
                                        </div>


                                        <div className="mb-4 grid grid-cols-2 gap-4">

                                            <div className="mb-4 grid grid-cols-1 gap-4">
                                                <div className="flex flex-box">
                                                    <div className="flex-grow mr-2">
                                                                <label htmlFor="salary" className="block text-sm font-medium text-gray-600">
                                                                    Monthly Salary
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="salary"
                                                                    name="salary"
                                                                    value={formData.salary}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Eg: 500"
                                                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                                    required
                                                                />
                                                                {formData.salary.length === 0 && (
                                                                    <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                                )}
                                                                {formData.salary.match(/[^0-9]/) && (
                                                                    <p className="text-red-500 text-xs italic">Please use only numerical characters.</p>
                                                                )}
                                                                {formData.salary.length >= 2 && !formData.salary.match(/[^0-9]/) && (
                                                                    <p className="text-green-500 text-xs italic">Looks Good</p>
                                                                )}



                                                    </div>


                                                    <div className="">
                                                        <label htmlFor="currency" className="block text-sm font-medium text-gray-600">
                                                            Currency
                                                        </label>
                                                        <select
                                                            id="currency"
                                                            name="currency"
                                                            value={formData.currency}
                                                            onChange={handleInputChange}
                                                            required
                                                            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md focus:outline-none focus:border-blue-300"
                                                        >
                                                            <option value="">Select Currency</option>
                                                            <option value="USD">USD</option>
                                                            <option value="EUR">EUR</option>
                                                            <option value="GBP">GBP</option>
                                                            <option value="AED">AED</option>
                                                            <option value="SAR">SAR</option>
                                                            <option value="QAR">QAR</option>
                                                            <option value="KWD">KWD</option>
                                                            <option value="OMR">OMR</option>
                                                            <option value="LKR">LKR</option>
                                                            {/* Add more currency options as needed */}
                                                        </select>

                                                        {formData.currency === 0 && (
                                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                        )}
                                                    </div>


                                                </div>


                                            </div>


                                            <div className="mb-4 grid grid-cols-2 gap-4">

                                                <div>
                                                    <label htmlFor="contractPeriod" className="block text-sm font-medium text-gray-600">
                                                        Contract Period
                                                    </label>
                                                    <select
                                                        id="contractPeriod"
                                                        name="contractPeriod"
                                                        value={formData.contractPeriod}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option >Select Contract Period</option>
                                                        <option value={"1"}>One Year / 1 Year</option>
                                                        <option value={"2"}>Two Years / 2 Years</option>
                                                        <option value={"3"}>Three Years / 3 Years</option>
                                                        <option value={"4"}>Four Years / 4 Years</option>
                                                        <option value={"5"}>Five Years / 5 Years</option>
                                                        <option value={"6"}>Six Years / 6 Years</option>

                                                    </select>

                                                    {formData.contractPeriod === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                </div>

                                                <div>
                                                    <label htmlFor="gender" className="block text-sm font-medium text-gray-600">
                                                        Gender
                                                    </label>
                                                    <select
                                                        id="gender"
                                                        name="gender"
                                                        value={formData.gender}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option >Select Gender</option>
                                                        <option value={"male"}>Male</option>
                                                        <option value={"female"}>Female</option>

                                                    </select>

                                                </div>
                                            </div>
                                        </div>



                                        {/* 3 */}

                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="nationality" className="block text-sm font-medium text-gray-600">
                                                        Nationality
                                                    </label>
                                                    <select
                                                        id="nationality"
                                                        name="nationality"
                                                        value={formData.nationality}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option value="">Select Country</option>
                                                        <option value="Sri Lankan">Sri Lankan</option>
                                                    </select>

                                                </div>
                                                <div>
                                                    <label htmlFor="religion" className="block text-sm font-medium text-gray-600">
                                                        Religion
                                                    </label>
                                                    <select
                                                        id="religion"
                                                        name="religion"
                                                        value={formData.religion}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                    >
                                                        <option value="">Select Religion</option>
                                                        <option value="Non-Muslim">Non-Muslim</option>
                                                        <option value="Muslim">Muslim</option>

                                                        {/* Add more options as needed */}
                                                    </select>
                                                </div>

                                            </div>

                                            <div className="mb-4 grid grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="DOB" className="block text-sm font-medium text-gray-600">
                                                        DOB (Month/ Date/ Year)
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="DOB"
                                                        name="DOB"
                                                        value={formData.DOB}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />

                                                    {formData.DOB.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}

                                                    {formData.DOB.length >= 2 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}
                                                </div>

                                                <div>
                                                    <label htmlFor="birthPlace" className="block text-sm font-medium text-gray-600">
                                                        Birth Place
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="birthPlace"
                                                        name="birthPlace"
                                                        value={formData.birthPlace}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        placeholder="Eg: Kurunegala"
                                                        required
                                                    />
                                                    {formData.birthPlace.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.birthPlace.length >= 4 && (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}

                                                </div>
                                            </div>
                                        </div>




                                        {/* 4 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="livingTown" className="block text-sm font-medium text-gray-600">
                                                        Living Town
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="livingTown"
                                                        name="livingTown"
                                                        value={formData.livingTown}
                                                        onChange={handleInputChange}
                                                        placeholder="Eg: Kurunegala"
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />

                                                    {formData.livingTown.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.livingTown.length >= 4 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="maritalStatus" className="block text-sm font-medium text-gray-600">
                                                        Marital Status
                                                    </label>
                                                    <select
                                                        id="maritalStatus"
                                                        name="maritalStatus"
                                                        value={formData.maritalStatus}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option>Select Marital Status</option>
                                                        <option value={"single"}>Single</option>
                                                        <option value={"married"}>Married</option>
                                                        <option value={"divorced"}>Divorced</option>
                                                        <option value={"widowed"}>Widowed</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="noOfChildren" className="block text-sm font-medium text-gray-600">
                                                        No Of Children
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="noOfChildren"
                                                        name="noOfChildren"
                                                        value={formData.noOfChildren}
                                                        placeholder="Eg: 1"
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />

                                                    {formData.noOfChildren.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.noOfChildren.length >= 1 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="Weight" className="block text-sm font-medium text-gray-600">
                                                        Weight (Kg)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="Weight"
                                                        name="Weight"
                                                        placeholder="Eg: 75"
                                                        value={formData.Weight}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {formData.Weight.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.Weight.length >= 1 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* 5 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="Height" className="block text-sm font-medium text-gray-600">
                                                        Height (cm)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Height"
                                                        name="Height"
                                                        placeholder="Eg: 165"
                                                        value={formData.Height}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {formData.Height === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.Height >= 1 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="Complexion" className="block text-sm font-medium text-gray-600">
                                                        Complexion
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Complexion"
                                                        name="Complexion"
                                                        placeholder="Eg: Fair"
                                                        value={formData.Complexion}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />

                                                    {formData.Complexion.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.Complexion.length >= 3 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {/*<div>*/}
                                                {/*    <label htmlFor="Age" className="block text-sm font-medium text-gray-600">*/}
                                                {/*        Age*/}
                                                {/*    </label>*/}
                                                {/*    <select*/}
                                                {/*        id="Age"*/}
                                                {/*        name="Age"*/}
                                                {/*        value={formData.Age}*/}
                                                {/*        onChange={handleInputChange}*/}
                                                {/*        className="mt-1 p-2 w-full border rounded-md"*/}
                                                {/*        required*/}
                                                {/*    >*/}
                                                {/*        <option value="">Select Age</option>*/}
                                                {/*        /!* Loop through ages from 1 to 85 *!/*/}
                                                {/*        {Array.from({ length: 65 }, (_, i) => i + 17).map((Age) => (*/}
                                                {/*            <option key={Age} value={Age}>*/}
                                                {/*                {Age}*/}
                                                {/*            </option>*/}
                                                {/*        ))}*/}
                                                {/*    </select>*/}
                                                {/*</div>*/}

                                                <div>
                                                    <label htmlFor="english" className="block text-sm font-medium text-gray-600">
                                                        English
                                                    </label>
                                                    <select
                                                        id="english"
                                                        name="english"
                                                        value={formData.english}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select proficiency</option>
                                                        <option value="excellent">Excellent</option>
                                                        <option value="good">Good</option>
                                                        <option value="fair">Fair</option>
                                                        <option value="poor">Poor</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>



                                        {/* 4 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="arabic" className="block text-sm font-medium text-gray-600">
                                                        Arabic
                                                    </label>
                                                    <select
                                                        id="arabic"
                                                        name="arabic"
                                                        value={formData.arabic}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                    >
                                                        <option value="">Select proficiency</option>
                                                        <option value="excellent">Excellent</option>
                                                        <option value="good">Good</option>
                                                        <option value="fair">Fair</option>
                                                        <option value="poor">Poor</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="Driving" className="block text-sm font-medium text-gray-600">
                                                        Driving
                                                    </label>
                                                    <select
                                                        id="Driving"
                                                        name="Driving"
                                                        value={formData.Driving}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="Sewing" className="block text-sm font-medium text-gray-600">
                                                        Sewing
                                                    </label>
                                                    <select
                                                        id="Sewing"
                                                        name="Sewing"
                                                        value={formData.Sewing}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="cooking" className="block text-sm font-medium text-gray-600">
                                                        Cooking
                                                    </label>
                                                    <select
                                                        id="cooking"
                                                        name="cooking"
                                                        value={formData.cooking}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>



                                                </div>
                                            </div>
                                        </div>


                                        {/* 7 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="washing" className="block text-sm font-medium text-gray-600">
                                                        Washing
                                                    </label>
                                                    <select
                                                        id="washing"
                                                        name="washing"
                                                        value={formData.washing}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>

                                                </div>
                                                <div>
                                                    <label htmlFor="cleaning" className="block text-sm font-medium text-gray-600">
                                                        Cleaning
                                                    </label>
                                                    <select
                                                        id="cleaning"
                                                        name="cleaning"
                                                        value={formData.cleaning}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="baby_sitting" className="block text-sm font-medium text-gray-600">
                                                        Baby Sitting
                                                    </label>
                                                    <select
                                                        id="baby_sitting"
                                                        name="baby_sitting"
                                                        value={formData.baby_sitting}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>

                                                </div>

                                            </div>
                                        </div>



                                        {/* 8 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="passportNo" className="block text-sm font-medium text-gray-600">
                                                        Passport Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="passportNo"
                                                        name="passportNo"
                                                        placeholder="Eg: N1234567"
                                                        value={formData.passportNo}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {formData.passportNo.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.passportNo.length >= 6 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="issuedPlace" className="block text-sm font-medium text-gray-600">
                                                        Issued Place
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="issuedPlace"
                                                        name="issuedPlace"
                                                        placeholder="Eg: Colombo"
                                                        value={formData.issuedPlace}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {formData.issuedPlace.length === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                    {formData.issuedPlace.length >= 4 &&  (
                                                        <p className="text-green-500 text-xs italic">Looks Good</p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="IssuedDate" className="block text-sm font-medium text-gray-600">
                                                        Issued Date (Month/ Date/ Year)
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="IssuedDate"
                                                        name="IssuedDate"
                                                        value={formData.IssuedDate}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                        required
                                                    />
                                                    {formData.IssuedDate === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                </div>

                                                <div>
                                                    <label htmlFor="ExpiryDate" className="block text-sm font-medium text-gray-600">
                                                        Expiry Date (Month/ Date/ Year)
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="ExpiryDate"
                                                        name="ExpiryDate"
                                                        value={formData.ExpiryDate}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                    {formData.ExpiryDate === 0 && (
                                                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                                    )}
                                                </div>

                                            </div>
                                        </div>


                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="">
                                                <label htmlFor="countries" className="block text-sm font-medium text-gray-600">
                                                    Select Countries (You can select multiple countries)
                                                </label>

                                                    <ReactTags
                                                        id={"countries"}
                                                        name={"countries"}
                                                        labelText="Select countries"
                                                        selected={selected}
                                                        suggestions={suggestions}
                                                        onAdd={handleAdd}
                                                        onDelete={handleDelete}
                                                        noOptionsText="No matching countries"

                                                    />



                                            </div>


                                            <div>
                                                <label htmlFor="telephone" className="block text-sm font-medium text-gray-600">
                                                    Telephone
                                                </label>
                                                <input
                                                    type="text"
                                                    id="telephone"
                                                    name="telephone"
                                                    placeholder="Eg: 0761231231"
                                                    value={formData.telephone}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                />

                                            </div>
                                        </div>

                                        <div>
                                            {formData.educationalQualifications.map((qualification, index) => (
                                                <div key={index} className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                                                    <div>
                                                        <label htmlFor={`educationalQualifications[${index}].qualification`} className="block text-sm font-medium text-gray-600">
                                                            Education Qualification
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id={`educationalQualifications[${index}].qualification`}
                                                            placeholder="Passed O/L"
                                                            name={`educationalQualifications[${index}].qualification`}
                                                            value={qualification}
                                                            onChange={(e) => handleQualificationChange(index, e.target.value)}
                                                            className="mt-1 p-2 w-full border rounded-md"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="flex items-center">
                                                        <button type="button" className="bg-red-500 hover:bg-red-900 text-white px-4 py-2 rounded-md flex mr-2" onClick={() => handleRemoveQualification(index)}>
                                                            <TrashIcon className="h-[25px] mr-2" /> Remove
                                                        </button>
                                                        {index === formData.educationalQualifications.length - 1 && (
                                                            <button type="button" className="bg-green-500 hover:bg-green-900 text-white px-4 py-2 rounded-md flex" onClick={handleAddQualification}>
                                                                <PlusIcon className="h-[25px] mr-2" /> Add Qualification
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}

                                        </div>


                                        {/* 9 */}

                                        <div>
                                            {formData.previousEmployments.map((employment, index) => (
                                                <div key={index} className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                                                    <div>
                                                        <label htmlFor={`previousEmployments[${index}].country`} className="block text-sm font-medium text-gray-600">
                                                            Past Employment Country
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id={`previousEmployments[${index}].country`}
                                                            placeholder="Eg: Saudi Arabia"
                                                            name={`previousEmployments[${index}].country`}
                                                            value={employment.country}
                                                            onChange={(e) => handlePreviousEmploymentChange(index, 'country', e.target.value)}
                                                            className="mt-1 p-2 w-full border rounded-md"
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor={`previousEmployments[${index}].duration`} className="block text-sm font-medium text-gray-600">
                                                            Past Employment Duration (Years)
                                                        </label>
                                                        <input
                                                            type="number"
                                                            id={`previousEmployments[${index}].duration`}
                                                            name={`previousEmployments[${index}].duration`}
                                                            placeholder="3"
                                                            value={employment.duration}
                                                            onChange={(e) => handlePreviousEmploymentChange(index, 'duration', e.target.value)}
                                                            className="mt-1 p-2 w-full border rounded-md"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="flex items-center">
                                                        <button type="button" className="bg-red-500 hover:bg-red-900 text-white px-4 py-2 rounded-md flex mr-2" onClick={() => handleRemovePreviousEmployment(index)}>
                                                            <TrashIcon className="h-[25px] mr-2" /> Remove
                                                        </button>
                                                        {index === formData.previousEmployments.length - 1 && (
                                                            <button type="button" className="bg-green-500 hover:bg-green-900 text-white px-4 py-2 rounded-md flex" onClick={handleAddPreviousEmployment}>
                                                                <PlusIcon className="h-[25px] mr-2" /> Add Past Employment
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>




                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="Photo" className="block text-sm font-medium text-gray-600">
                                                    Photo
                                                </label>
                                                <input
                                                    type="file"
                                                    id="Photo"
                                                    name="Photo"
                                                    onChange={(e) => handleFileChange(e, setPhotoPreview, setFile, 'Photo')}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    accept="image/*" // Allow only image files
                                                    required
                                                />
                                            </div>
                                            {/* Preview Image */}
                                            {photoPreview && (
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-600">
                                                        Preview Image
                                                    </label>
                                                    <img src={photoPreview} alt="Preview" className="border border-black p-4 w-auto h-[35vh]" />
                                                </div>
                                            )}
                                        </div>

                                        {/* Cover Photo Upload */}
                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="coverPhoto" className="block text-sm font-medium text-gray-600">
                                                    Cover Photo
                                                </label>
                                                <input
                                                    type="file"
                                                    id="coverPhoto"
                                                    name="coverPhoto"
                                                    onChange={(e) => handleFileChange(e, setCoverPhotoPreview, setCoverPhotoFile, 'coverPhoto')}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    accept="image/*" // Allow only image files
                                                    required
                                                />
                                            </div>
                                            {/* Preview Cover Photo */}
                                            {coverPhotoPreview && (
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-600">
                                                        Preview Cover Photo
                                                    </label>
                                                    <img src={coverPhotoPreview} alt="Preview" className="border border-black p-4 w-auto h-[35vh]" />
                                                </div>
                                            )}
                                        </div>

                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                            disabled={disabled} // Disable button when disabled state is true
                                        >
                                            {loading ? (
                                                <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 3.582 8 8 8v-4c-2.481 0-4.736-.897-6.472-2.391l2.472-2.472 1.414 1.414-3.5 3.5zM20 12a8 8 0 11-16 0 8 8 0 0116 0z"></path>
                            </svg>
                            Submitting...
                        </span>
                                            ) : (
                                                'Submit'
                                            )}
                                        </button>


                                    </form>
                                    {/* End of Form */}

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
};

export default NewApplicant;
