import React, {useEffect, useRef, useState} from 'react';
import logo from '../images/logo/cv-logo.png'; // Import your logo image
import logo1 from '../images//job-listing/12345.jpg'; // Import your logo image
import html2pdf from 'html2pdf.js';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import MySQLUrl from "../DBConfig";
import {ArrowDownTrayIcon, ArrowUturnLeftIcon} from "@heroicons/react/16/solid";
import Breadcrumb from "./breadcrumb";
import {HomeIcon} from "@heroicons/react/24/solid";
import Footer from "./footer";
import {toast, ToastContainer} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css';

import cvHeader from '../images/cv/cv-header.webp';

const Pdf = () => {
    const pdfRef = useRef();
    const [loading, setLoading] = useState(true);
    const [applicant, setApplicant] = useState({});
    const [qualification, setQualification] = useState({});
    const [employment, setEmployment] = useState({});
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());

    const { applicantId } = useParams();

    useEffect(() => {
        const fetchData = async () => {

            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                try {
                    const applicantResponse = await axios.get(`${MySQLUrl}/applicant/${applicantId}`);
                    setApplicant(applicantResponse.data);
                    if (!applicantResponse.data) {
                        setLoading(true);
                    } else {
                        try {
                            const qualificationResponse = await axios.get(`${MySQLUrl}/applicant/qualification/${applicantId}`);
                            if (qualificationResponse.data !== null) {
                                setQualification(qualificationResponse.data);
                            }

                            const employmentResponse = await axios.get(`${MySQLUrl}/applicant/prev-employment/${applicantId}`);
                            if (employmentResponse.data !== null) {
                                setEmployment(employmentResponse.data);
                            }

                        } catch (error) {
                            console.error('Error fetching qualification or employment:', error);
                            // Handle qualification or employment fetch error here
                        }
                    }
                } catch (error) {
                    console.error('Error fetching applicant data:', error);
                    // Handle applicant fetch error here
                } finally {
                    setLoading(false);
                }

            }
        };

        fetchData();
    }, [applicantId]);



    const saveAsPdf = () => {

        confirmAlert({
            title: 'Confirm Downloading',
            message: 'Are you sure you want to download this CV?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const input = pdfRef.current;
                        const filename = `${applicant.fullName}_${applicant.postApplied}.pdf`;
                        html2pdf()
                            .from(input)
                            .set({
                                margin: [0, 0, 0, 0],
                                filename: filename,
                                image: {type: 'jpeg', quality: 0.98},
                                html2canvas: {scale: 3},
                                jsPDF: {unit: 'cm', format: 'a4', orientation: 'portrait'}
                            })
                            .save();
                        toast.success("PDF saved successfully");
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };

    const goBack = () =>{

        confirmAlert({
            title: 'Go Back',
            message: 'Are your sure you want to go back?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        navigate('/applicant');
                        console.log('Button clicked!');
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };

    const DOB = applicant.DOB;
    const dobDate = new Date(DOB);
    const formattedDate = `${dobDate.getDate()}/${dobDate.getMonth() + 1}/${dobDate.getFullYear()}`;

    const IssuedDate = applicant.IssuedDate;
    const issuedDate = new Date(IssuedDate);
    const formattedIssuedDate = `${issuedDate.getDate()}/${issuedDate.getMonth() + 1}/${issuedDate.getFullYear()}`;

    const ExpiryDate = applicant.ExpiryDate;
    const expiryDate = new Date(ExpiryDate);
    const formattedExpiryDate = `${expiryDate.getDate()}/${expiryDate.getMonth() + 1}/${expiryDate.getFullYear()}`;

    // Convert images to base64
    const getBase64Image = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'arraybuffer' });
            const base64Image = Buffer.from(response.data, 'binary').toString('base64');
            return `data:image/jpeg;base64,${base64Image}`;
        } catch (error) {
            console.error('Error converting image to base64:', error);
            return null;
        }
    };

    useEffect(() => {
        const loadImage = async () => {
            try {
                if (applicant.photo) {
                    // Fetch the image from the backend
                    const responsePhoto = await axios.get(`${MySQLUrl}/${applicant.photo.replace(/\\/g, '/')}`, { responseType: 'blob' });
                    // Convert the received image to Base64
                    const base64Photo = await convertBlobToBase64(responsePhoto.data);
                    // Update the state with the Base64 image
                    setApplicant(prevState => ({ ...prevState, base64Photo }));
                }
                if (applicant.coverPhoto) {
                    // Fetch the cover image from the backend
                    const responseCoverPhoto = await axios.get(`${MySQLUrl}/${applicant.coverPhoto.replace(/\\/g, '/')}`, { responseType: 'blob' });
                    // Convert the received cover image to Base64
                    const base64CoverPhoto = await convertBlobToBase64(responseCoverPhoto.data);
                    // Update the state with the Base64 cover image
                    setApplicant(prevState => ({ ...prevState, base64CoverPhoto }));
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        };

        // Function to convert Blob to Base64
        const convertBlobToBase64 = blob => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        // Call the loadImage function when the component mounts or when image URLs change
        loadImage();
    }, [applicant.photo, applicant.coverPhoto]);

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Applicants', url: '/applicant' },
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const formattedTime = currentTime.toLocaleTimeString();


    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="block bg-gray-100 text-neutral-800 overflow-y-auto">

                <div className="mx-4 ">
                    <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex">


                    <div className="w-4/12 text-black p-4 shadow-md mx-auto pt-0 mt-0 block bg-gray-100 ">
                        <div className="bg-white mt-2 rounded-lg py-3">


                            <div className="block items-center justify-center ">
                                <div className="flex items-center justify-center pt-3">
                                    <div className="rounded-full overflow-hidden border-2 border-gray-500 w-[155px] h-[155px]">
                                        {applicant.base64Photo && (
                                            <img className="w-full h-full object-cover" src={applicant.base64Photo} alt="Sample Image"/>
                                        )}
                                    </div>

                                </div>

                                <div className="block pt-3 px-3">
                                    <h3 className="font-bold text-xl">Bio Details</h3>
                                    <p className="pt-2 text-sm font-medium tracking-widest text-gray-800 capitalize lg:text-base">
                                        Name in Full: {applicant.fullName}
                                    </p>
                                    <p className="pt-2 text-sm font-medium tracking-widest text-gray-800 capitalize lg:text-base">
                                        Age: {applicant.Age} Years
                                    </p>
                                    <p className="pt-2 text-sm font-medium tracking-widest text-gray-800 capitalize lg:text-base">
                                        Post Applied: {applicant.postApplied}
                                    </p>

                                    <p className="pt-2 text-sm font-medium tracking-widest text-gray-800 capitalize lg:text-base">
                                        Nationality: {applicant.nationality}
                                    </p>
                                    <p className="pt-2 text-sm font-medium tracking-widest text-gray-800 capitalize lg:text-base">
                                        Passport No. : {applicant.passportNo}
                                    </p>
                                    <p className="pt-2 text-sm font-medium tracking-widest text-gray-800 capitalize lg:text-base">
                                        Telephone. : {applicant.telephone ? applicant.telephone : 'Not available'}
                                    </p>



                                </div>



                            </div>

                            <div className="flex mt-2 pt-4">
                                <button
                                    className="block items-center justify-center h-[70px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2"
                                    onClick={saveAsPdf}>
                            <span className="flex items-center">
                                <ArrowDownTrayIcon className="mr-2 h-[40px]" />
                                <span>Download as PDF</span>
                            </span>
                                </button>

                                <button
                                    className="block items-center justify-center h-[70px] bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mx-2"
                                    onClick={goBack}>
                            <span className="flex items-center">
                                <ArrowUturnLeftIcon className="mr-2 h-[40px]" />
                                <span>Go Back</span>
                            </span>
                                </button>
                            </div>


                        </div>
                    </div>




                    <div className="h-[76vh] w-8/12 overflow-y-scroll overflow-x-hidden">
                        <div ref={pdfRef} className="text-black p-4 bg-white shadow-md mx-auto mt-0 border-4 border-orange-700 uppercase" style={{ height: "29.6cm", width: "21cm", backgroundColor: "#f3f4f6" }}>
                            {/* Logo and Company Name */}
                            <div className="flex justify-center items-center mt-0">

                                 <img src={cvHeader} alt="CV Header" className="h-auto max-h-32 mt-0" /> {/* Adjust the maximum height as needed */}

                                {/*<div className="w-2/12 ">*/}
                                {/*    <img src={logo} alt="Company Logo" className="h-auto max-h-20" /> /!* Adjust the maximum height as needed *!/*/}
                                {/*</div>*/}
                                {/*<div className="w-10/12 text-center ">*/}
                                {/*    <p className="text-2xl font-black mb-0.5 m-0 p-0 italic text-blue-800">TEE-GEE AGENCY(PVT) LTD</p>*/}
                                {/*    <h6 className="text-lg capitalize font-medium m-0 p-0 ">Manpower, Travel Agent & Allied Services</h6>*/}
                                {/*    <h2 className="text-lg font-black mb-2 text-blue-800">تي جي )الخاصة( المحدودة</h2> /!* Arabic Company Name *!/*/}
                                {/*</div>*/}
                            </div>

                            <div className="w-full bg-yellow-400">
                                <h2 className="text-lg text-red-700 uppercase font-bold text-center m-0 p-0">Application for Employment</h2>
                                <h3 className="text-md text-red-700 font-medium text-center pb-2">إستمارة توظيف</h3> {/* Arabic Company Name */}
                            </div>
                            {/* Content goes here */}


                            <div className="flex justify-between items-center mb-2">
                                <div className="w-3/12 ">
                                    {applicant.base64Photo && (
                                        <img
                                            src={applicant.base64Photo}
                                            alt="CV holder Image"
                                            className=" border border-gray-800 p-1 w-[170px] h-[170px]"
                                        />
                                    )}
                                </div>



                                <div className="w-9/12" >
                                    <table className="w-full mt-2 text-[14px]" >
                                        <tr>
                                            <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">REF .NO</td>
                                            <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.teegee_ref}</td>
                                            <td className="border-1 font-bold text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">الرقم المرجعي</td>
                                        </tr>
                                        <tr>
                                            <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">Post Applied For</td>
                                            <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.postApplied}</td>
                                            <td className="border-1  font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">الموقف التطبيقي</td>
                                        </tr>
                                        <tr>
                                            <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">Monthly Salary</td>
                                            <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.monthly_Salary}</td>
                                            <td className="border-1 font-bold text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">الراتب الشهري</td>
                                        </tr>
                                        <tr>
                                            <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">Contract Period</td>
                                            <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.contractPeriod}</td>
                                            <td className="border-1 font-bold text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">فترة العقد</td>
                                        </tr>

                                        <tr>
                                            <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">Name IN Full</td>
                                            <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0 w-[234px]">{applicant.fullName}</td>
                                            <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">الاسم الكامل</td>
                                        </tr>
                                    </table>

                                </div>


                            </div>



                            <div className="flex justify-between items-center mb-1">
                                <div className="w-1/2 ">
                                    <div className="">
                                        <h2 className="bg-yellow-400 pb-2 font-bold text-[14px] text-red-500 pl-2"> DETAILS OF APPLICANT</h2>
                                        <table className="w-full  text-[13px]">
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pl-1 pb-1 pt-0 mt-0">NATIONALITY</td>
                                                <td className="border-1  border-orange-700 pl-1 w-4/12 pb-1 pt-0 mt-0">{applicant.nationality}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pl-1 w-3/12 pb-1 pt-0 mt-0">الوطنية</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">RELIGION</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.religion}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">الدين</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">DATE OF BIRTH</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{formattedDate}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">تاريخ الطفولة</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">PLACE OF BIRTH</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.birthPlace}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">مكان الطفولة</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">LIVING TOWN</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.livingTown}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">مكان الحياة</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">MARITAL STATUS</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.maritalStatus}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">الحالة البحرية</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">NO. OF CHILDREN</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.noOfChildren}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">عدد الأطفال</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">WEIGHT</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.Weight} Kg</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">الوزن</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">HEIGHT</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.Height}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">عالية</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">COMPLEXION</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.Complexion}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">الامتثال</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">AGE</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.Age} Yrs</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">العمر</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className=" mt-2">
                                        <h2 className="bg-yellow-400 pb-2 font-bold text-[14px] text-red-500 pl-2">KNOWLEDGE OF LANGUAGES</h2>
                                        <table className="w-full text-[13px]">
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0" colspan="2">English</td>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0"  colspan="2">Arabic</td>


                                            </tr>
                                            <tr>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">EXCELLENT</td>
                                                <td className="border-1  border-orange-700 pb-1 pt-0 mt-0">{applicant.english === 'excellent' ? '✓' : ''}</td>
                                                <td className="border-1  border-orange-700 pb-1 pt-0 mt-0">{applicant.arabic === 'excellent' ? '✓' : ''}</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">ممتاز</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">GOOD</td>
                                                <td className="border-1  border-orange-700 pb-1 pt-0 mt-0">{applicant.english === 'good' ? '✓' : ''}</td>
                                                <td className="border-1  border-orange-700 pb-1 pt-0 mt-0">{applicant.arabic === 'good' ? '✓' : ''}</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">جيد</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">FAIR</td>
                                                <td className="border-1  border-orange-700 pb-1 pt-0 mt-0">{applicant.english === 'fair' ? '✓' : ''}</td>
                                                <td className="border-1  border-orange-700 pb-1 pt-0 mt-0">{applicant.arabic === 'fair' ? '✓' : ''}</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">متوسط</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">POOR</td>
                                                <td className="border-1  border-orange-700 pb-1 pt-0 mt-0">{applicant.english === 'poor' ? '✓' : ''}</td>
                                                <td className="border-1  border-orange-700 pb-1 pt-0 mt-0">{applicant.arabic === 'poor' ? '✓' : ''}</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0 ">ضعيف</td>
                                            </tr>



                                            <td className="border-1 border-orange-700 pb-1 pt-0 pl-1 mt-0 font-bold text-red-600" colspan="4">EDUCATIONAL QUALIFICATION</td>

                                            {qualification && qualification.map && qualification.slice(0, 2).map((qual, index) => (
                                                <tr key={index} className="border-1 border-orange-700 pl-1 pt-0 mt-0" colspan="4">
                                                    <td className="pb-1 pl-1"> {qual.qualification}</td>

                                                </tr>
                                            ))}




                                        </table>
                                    </div>
                                    <div className="mt-2">
                                        <h2 className="bg-yellow-400 pb-1 font-bold text-[14px] text-red-500 pl-2"> PREVIOUS EMPLOYMENT ABROAD</h2>
                                        <table className="w-full text-[13px]">
                                            {employment && employment.map && employment.slice(0, 2).map((employ, index) => (
                                                <tr key={index} className="border-1 border-orange-700 pb-1 pt-0 mt-0" colspan="4">
                                                    <td className="pb-1 pl-1">{employ.country}</td>
                                                    <td className="pb-1 pl-1">{employ.duration}</td>

                                                </tr>
                                            ))}



                                        </table>
                                    </div>
                                    <div className="mt-2">
                                        <h2 className="bg-yellow-400 pb-2 font-bold text-[14px] text-red-500 pl-2"> WORK EXPERIENCE</h2>
                                        <table className="w-full text-[13px]">
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">DRIVING</td>
                                                <td className="border-1   border-orange-700 pb-1 pl-1 w-1/12">{applicant.Driving === 'can' ? ' ✓' : ''}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 w-2/12">القيادة</td>
                                                <td className="border-1 font-medium text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">WASHING</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1  w-1/12">{applicant.washing === 'can' ? ' ✓' : ''}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 w-2/12">غسيل</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">SEWING</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1  w-1/12">{applicant.Sewing === 'can' ? ' ✓' : ''}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 w-2/12">الخياطة</td>
                                                <td className="border-1 font-medium text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">CLEANING</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1  w-1/12">{applicant.cleaning === 'can' ? ' ✓' : ''}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 w-2/12">التنظيف</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">COOKING</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 w-1/12">{applicant.cooking === 'can' ? ' ✓' : ''}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 w-2/12">الطبخ</td>
                                                <td className="border-1  font-medium text-red-600 border-orange-700 pb-1 pl-1 pt-0 mt-0">BABY SITTING</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1  w-1/12">{applicant.baby_sitting === 'can' ? ' ✓' : ''}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-1 pl-1 w-2/12">الجلوس</td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>


                                <div className="w-1/2 ">
                                    <div className=" mb-2 pl-1">
                                        <h2  className="bg-yellow-500 pb-2 font-bold text-[14px] text-red-500 pl-2"> PASSPORT DETAILS</h2>
                                        <table className="w-full  text-[13px]">
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">Passport .NO:</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.passportNo}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-2 pl-1 pt-0 mt-0">جواز السفر ن</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">Place of Issue</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{applicant.issuedPlace}</td>
                                                <td className="border-1 font-bold text-red-600  border-orange-700 pb-2 pl-1 pt-0 mt-0">مكان الإصدار</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">Date of Issue</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{formattedIssuedDate}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-2 pl-1 pt-0 mt-0">تاريخ الإصدار</td>
                                            </tr>
                                            <tr>
                                                <td className="border-1 font-medium text-red-600  border-orange-700 pb-1 pl-1 pt-0 mt-0">Date of Expiry</td>
                                                <td className="border-1  border-orange-700 pb-1 pl-1 pt-0 mt-0">{formattedExpiryDate}</td>
                                                <td className="border-1 font-bold text-red-600 border-orange-700 pb-2 pl-1 pt-0 mt-0v">تاريخ انتهاء الصلاحية</td>
                                            </tr>


                                        </table>
                                    </div>
                                    <div className="bg-emerald-300 pl-1" style={{ width: "372px", height: "535px" }}>
                                        {applicant.base64CoverPhoto && (
                                            <img
                                                src={applicant.base64CoverPhoto}
                                                alt="CV holder Image"
                                                className="border border-gray-800 p-1"
                                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                            />
                                        )}
                                    </div>
                                </div>

                            </div>


                            <h5 className=" text-xs text-center font-medium text-red-600">I HEREBY DECLARE THAT ABOVE PARTICULARS FURNISHED BY ME ARE TRUE AND ACCURATE TO THE BEST OF MY KNOWLEDGE.</h5>


                        </div>
                    </div>


                </div>
            </div>

        </>
    );
};

export default Pdf;
